import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import { RouteWithLayout, ProtectedRouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  Login as LoginView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';

const Routes = ({ isAuthenticated, isVerifying, user } ) => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <ProtectedRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(Routes);
